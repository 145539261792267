import React from 'react';
import { useDispatch } from 'react-redux'
import Layout from "../../components/Investor/layout";
import SEO from "../../components/seo"
import styles from "../../components/innerPage.module.css"
import { graphql,  Link } from 'gatsby';
import { deriveHomePathFromSpace, isNullUndefined, addDisclaimersToCmsLinks, richTextOptions, capitalizeFirstLetter, transformMarkdownToReact } from "../../util"
import ScrollAnimation from 'react-animate-on-scroll';
import {showInvestorDisclaimerPopup} from "../../actions/investor/prompts/disclaimers/"
import {toggleInvestorDisclaimerPopup} from "../../controllers/investor/disclaimer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultInvestorDisclaimerPopupConfigs, config } from '../../util/configs'
import { Markup } from 'interweave';
import queryString from "query-string"
import { ValidateRoute } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper"

const { richTextFromMarkdown } = require('@contentful/rich-text-from-markdown');

export const pageQueryDefaultTemplateInvestor = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulDefaultTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
            bannerImage {
                file {
                    url
                }
            }
            spaceId
            slug
            title
            bodyText {
                json
            }
            table {
                table
                childMarkdownRemark {
                    html
                    htmlAst
                }
            }
            parentMenu {
                default_template {
                  parentMenu {
                    title
                    slug
                  }
                }
            }
        }
    }
`

const DefaultTemplatePageInvestor = (props) => {

    const dispatch = useDispatch()

    const {bannerImage, slug, title, spaceId, parentMenu, bodyText, table} = props.data.contentfulDefaultTemplate;

    let richText = documentToReactComponents(isNullUndefined(bodyText) ? bodyText : bodyText.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)
 
    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultInvestorDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showInvestorDisclaimerPopup(actionConfig));
    }

    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);

    React.useEffect(() => {
        let {veevaDisclaimer} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }
  
    }, []);
    return ( 
           
        <TemplateWrapper>
            <Layout>
        <SEO title={`${capitalizeFirstLetter(title)} | Investor or Media Relations`} />
        <div className={styles.banner} style={{background:`url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
        <div className={styles.overlay}></div>
                            <div className="container">
                               <div className="row">
                                   <div className="col-12 col-sm-12 col-md-12 ">
                                    <h1 className="">
                                       {title}
                                    </h1> 
                                   <ol className="custom_breadcrumb">
                                   <li className="breadcrumb-item">
                                       <Link to="/Investor/home">
                                           Home
                                       </Link>
                                    </li>
                                        {!isNullUndefined(parentMenu) && !isNullUndefined(parentMenu.default_template) && <li className="breadcrumb-item"><a href="#">
                                            {parentMenu.default_template[0].parentMenu.title}    
                                        </a></li>}
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {title}
                                        </li>
                                    </ol>
                                    
                                   </div>
                                   </div>
                            </div>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                <div className={styles.content}>
                        <div className="container pt-5 pb-5 default_template">
                        <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                        {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}

                        {!isNullUndefined(table) && !isNullUndefined(table.childMarkdownRemark) && !isNullUndefined(table.childMarkdownRemark.htmlAst) && transformMarkdownToReact(table.childMarkdownRemark.htmlAst, showExternalLinkDisclaimer)}
                          
                         </div>
                     

                    </div>
                        </div>
               </div>
             </ScrollAnimation>
  
      </Layout>
        </TemplateWrapper>
     );
}
 
export default DefaultTemplatePageInvestor;